import React, { useEffect, useState } from "react";
import { requestForgotPasswordPage } from "../Services/forgotPassword";

const ForgotPasswordPage = () => {
  // state for showing verification message
  const [forgotPasswordPageMessage, setForgotPasswordPageMessage] =
    useState("");
  const [userName, setUserName] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const userMailId = queryParams.get("ml");
    const userName = queryParams.get("n");
    setUserName(userName);
    sessionStorage.setItem("token", token);
    if (!userMailId) {
      setForgotPasswordPageMessage("Invalid or missing user email Id.");
      setIsError(true);
      return;
    }

    const verifyForgotPasswordPage = async () => {
      try {
        const response = await requestForgotPasswordPage(`email=${userMailId}`);
        setForgotPasswordPageMessage(
          response?.message || response?.data || "Verification Successful!"
        );
        setIsError(false);
      } catch (error) {
        setForgotPasswordPageMessage(
          error?.response?.data?.message ||
            "Verification failed. Please try again."
        );
        setIsError(true);
      }
    };

    verifyForgotPasswordPage();
  }, []);

  return (
    <>
      {isError ? (
        <div className="message-content">
          <p className="message-text">
            Unfortunately, there was an issue sending the email. Please try
            again later or contact support if the problem persists.
          </p>
        </div>
      ) : (
        <div className="verification-container">
          <h4>Hello, {userName}</h4>

          <div className="message-content">
            <p className="message-text">
              Email Sent Successfully ! Please check your email id for new
              password.
            </p>

            <h3 className="steps-header">
              Please follow following steps to change your password :
            </h3>
            <ol className="steps-list">
              <li>Please login application with your credentials.</li>
              <li>Go user account icon and click on change password.</li>
              <li>In change password screen, update your password.</li>
            </ol>

            <p className="thanks-text">Thanks !</p>
            <p className="team-signature">Team JetaTech</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordPage;
