/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; // Import routing components
import { useAuth } from './AuthContext';
import Download from './Components/Download';
import EmailVerification from './Components/EmailVerification';
import Footer from './Components/Footer';
import ForgotPasswordPage from './Components/ForgotPasswordPage';
import EmailForm from './Components/Forms/Email';
import TextForm from './Components/Forms/Text';
import UrlForm from './Components/Forms/Url';
import VCardForm from './Components/Forms/VCard';
import WiFiForm from './Components/Forms/WiFi';
import Header from './Components/Header';
import Tabs from './Components/Tabs';
import { AppContext } from './Context';
import { useLoginModal } from './LoginModalContext';
import AdComponent from './Components/AdComponent';
import ReactGA from "react-ga4";

const tabs = [
  {
    label: 'Link',
    Component: UrlForm
  },
  {
    label: 'Text',
    Component: TextForm
  },
  {
    label: 'E-mail',
    Component: EmailForm
  },
  {
    label: 'VCard',
    Component: VCardForm,
    requiresAuth: true
  },
  {
    label: 'WiFi',
    Component: WiFiForm,
    requiresAuth: true
  }
];

const defaultBrand = '';

const initialOptions = {
  size: 1000,
  removeBrand: false,
  imageMargin: 20,
  mainShape: 'dots',
  shapeColor: '#0084C7',  // Color matching the blue in the logo
  squareShape: 'extra-rounded',
  squareColor: '#FF7C1F',  // Color matching the orange in the logo
  cornersDotShape: 'extra-rounded',
  cornersDotColor: '#0084C7',  // Color matching the blue in the logo
  image: "/img/jetatechlogo.png"
};


const reducer = (state, action) => {
  switch (action.type) {
    case 'offcanvas-toggle':
      return {
        ...state,
        offcanvas: !state.offcanvas
      };
    case 'offcanvas-close':
      return {
        ...state,
        offcanvas: false
      };
    default:
      return state;
  }
};

const TRACKING_ID = "G-6CXX2SWV93"; // YOUR_OWN_TRACKING_ID

function App() {
  const { qrCode, canvasRef } = useContext(AppContext);
  const { token } = useAuth();
  const { openLoginModal } = useLoginModal();
  const [options, setOptions] = useState(initialOptions);
  const [{ offcanvas }, dispatch] = useReducer(reducer, {
    offcanvas: false
  });

  const offcanvasRef = useRef(null);
  const uploadRef = useRef(null);
  const uploadError = useRef('');

  const [isQrCodeReady, setIsQrCodeReady] = useState(false);

  const handleQrCodeGenerated = () => {
    setIsQrCodeReady(true);
  };

  useEffect(() => {
    const savedValues = localStorage.getItem('qr-code');
    if (savedValues) {
      setOptions(JSON.parse(savedValues));
    }
  }, []);

  const handleOptions = (event) => {
    const { type, name, value, checked, files } = event.target;

    setOptions((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (!files) return;

    const image = files[0];
    uploadError.current = '';

    if (image) {
      // Check supported formats
      if (!image.type.match('image.*')) {
        uploadError.current = 'Error: File is not supported.';
        return;
      }

      // Check max size (2M in Bytes)
      if (image.size > 2097152) {
        uploadError.current = 'Error: Maximum file size is 2 MB';
        return;
      }

      const fileReader = new FileReader();

      fileReader.onerror = (err) => {
        uploadError.current = `Failed: ${err}`;
      };

      fileReader.onload = () => {
        setOptions((prev) => ({
          ...prev,
          image: fileReader.result
        }));
      };
      fileReader.readAsDataURL(image);
    }
  };

  const handleOffcanvas = () => dispatch({ type: 'offcanvas-toggle' });

  const handleSave = (event) => {
    const button = event.currentTarget;
    button.innerText = 'Saving..';

    setTimeout(() => {
      button.innerText = 'Save Style';
    }, 1000);

    localStorage.setItem('qr-code', JSON.stringify(options));
  };

  const handleSavedValues = () => {
    const savedValues = localStorage.getItem('qr-code');
    if (savedValues) {
      setOptions(JSON.parse(savedValues));
    }
  };

  const handleResetOptions = () => {
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
    setOptions(initialOptions);
  };

  const handleResetImage = () => {
    if (uploadRef.current) {
      uploadRef.current.value = '';
      uploadError.current = '';
      setOptions((prev) => ({
        ...prev,
        image: defaultBrand
      }));
    }
  };

  useEffect(() => {
    const image = options.removeBrand ? '' : options.image;
    qrCode.update({
      width: options.size,
      height: options.size,
      image,
      dotsOptions: {
        type: options.mainShape,
        color: options.shapeColor
      },
      cornersSquareOptions: {
        type: options.squareShape,
        color: options.squareColor
      },
      cornersDotOptions: {
        type: options.cornersDotShape,
        color: options.cornersDotColor
      },
      imageOptions: {
        margin: options.imageMargin
      }
    });
  }, [qrCode, options]);

  useEffect(() => {
    window.onclick = (event) => {
      if (offcanvas && offcanvasRef.current) {
        if (event.target === offcanvasRef.current) dispatch({ type: 'offcanvas-close' });
      }
    };
  }, [offcanvas]);

  const handleTabSelect = (index) => {
    if (tabs[index].requiresAuth && !token) { // Check if token exists
      openLoginModal();
      return false; // Prevent tab change
    }
    return true; // Allow tab change
  };

  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 with the tracking ID
    ReactGA.initialize(TRACKING_ID);
    // Track page views on initial load
    ReactGA.send({ hitType: "pageview", page: location?.pathname + location?.search });
  }, [location]);

  return (
    <div className='d-flex flex-column App'>
      <Header currentRoute={location.pathname} />
      <main className='content' style={{ minHeight: "92vh" }}  >
        <Routes>
          <Route path="/" element={
            <>
              <div className='container'>
                <div className='row justify-content-between g-5 py-5 mt-1'>
                  <div className='col-12 col-md-6 col-lg-6'>
                    <Tabs className='my-3' tabs={tabs} type='pills' onTabSelect={handleTabSelect} onQrCodeGenerated={handleQrCodeGenerated} />
                    <AdComponent />
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='qr-code-container'>
                      <div className='qr-code text-center mx-auto' ref={canvasRef} />
                      <div className='customization'>
                        <div className='py-2'>
                          <input
                            id='size'
                            className='form-range px-2'
                            type='range'
                            name='size'
                            min='500'
                            max='1500'
                            step='50'
                            value={options.size}
                            onChange={handleOptions}
                          />
                          <div className='d-flex justify-content-between small'>
                            <span className='text-muted'>Low Quality</span>
                            <span className='fw-bold'>{`${options.size} x ${options.size} Px`}</span>
                            <span className='text-muted'>High Quality</span>
                          </div>
                        </div>

                        <div className='d-grid py-2'>
                          <button className='btn btn-primary py-2' type='button' onClick={handleOffcanvas}>
                            Customize QR Code
                          </button>
                        </div>
                        <div className='d-grid py-2'>
                          <div className='form-check form-switch'>
                            <input
                              id='removeBrand'
                              className='form-check-input'
                              type='checkbox'
                              name='removeBrand'
                              checked={options.removeBrand}
                              onChange={handleOptions}
                            />
                            <label className='form-check-label' htmlFor='removeBrand'>
                              Remove Brand
                            </label>
                          </div>
                        </div>
                        {isQrCodeReady && (<Download />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`offcanvas offcanvas-start${offcanvas ? ' show' : ''}`}
                style={offcanvas ? { visibility: 'visible' } : { visibility: 'hidden' }}
                tabIndex={-1}
              >
                <div className='offcanvas-header'>
                  <h5 className='offcanvas-title'>Customization</h5>
                  <button type='button' className='btn-close text-reset' aria-label='Close' onClick={handleOffcanvas} />
                </div>
                <div className='offcanvas-body'>
                  <div className='main-shape'>
                    <div className='row'>
                      <label htmlFor='mainShape' className='form-label'>
                        Main Shape
                      </label>
                      <div className='col-8'>
                        <select
                          id='mainShape'
                          className='form-select'
                          name='mainShape'
                          value={options.mainShape}
                          onChange={handleOptions}
                        >
                          <option value='square'>Square</option>
                          <option value='dots'>Dots</option>
                          <option value='rounded'>Rounded</option>
                          <option value='extra-rounded'>Extra Rounded</option>
                        </select>
                      </div>
                      <div className='col-4'>
                        <input
                          id='shapeColor'
                          className='form-control form-control-color'
                          type='color'
                          name='shapeColor'
                          value={options.shapeColor}
                          onChange={handleOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='square-shape'>
                    <div className='row pt-3'>
                      <label htmlFor='squareShape' className='form-label'>
                        Square In Corners
                      </label>
                      <div className='col-8'>
                        <select
                          id='squareShape'
                          className='form-select'
                          name='squareShape'
                          value={options.squareShape}
                          onChange={handleOptions}
                        >
                          <option value='square'>Square</option>
                          <option value='extra-rounded'>Extra Rounded</option>
                          <option value='rounded'>Rounded</option>
                        </select>
                      </div>
                      <div className='col-4'>
                        <input
                          id='squareColor'
                          className='form-control form-control-color'
                          type='color'
                          name='squareColor'
                          value={options.squareColor}
                          onChange={handleOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='corners-dot-shape'>
                    <div className='row pt-3'>
                      <label htmlFor='cornersDotShape' className='form-label'>
                        Dot In Corners
                      </label>
                      <div className='col-8'>
                        <select
                          id='cornersDotShape'
                          className='form-select'
                          name='cornersDotShape'
                          value={options.cornersDotShape}
                          onChange={handleOptions}
                        >
                          <option value='dot'>Dot</option>
                          <option value='square'>Square</option>
                          <option value='extra-rounded'>Extra Rounded</option>
                          <option value='rounded'>Rounded</option>
                        </select>
                      </div>
                      <div className='col-4'>
                        <input
                          id='cornersDotColor'
                          className='form-control form-control-color'
                          type='color'
                          name='cornersDotColor'
                          value={options.cornersDotColor}
                          onChange={handleOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='image'>
                    <div className='row pt-3'>
                      <label htmlFor='image' className='form-label'>
                        Image
                      </label>
                      <div className='col-12'>
                        <input
                          id='image'
                          className='form-control'
                          type='file'
                          name='image'
                          accept='image/*'
                          ref={uploadRef}
                          onChange={handleOptions}
                        />
                      </div>
                      {uploadError.current && <span className='error'>{uploadError.current}</span>}
                    </div>
                    <div className='d-grid d-md-flex justify-content-center py-3'>
                      <button className='btn btn-outline-secondary btn-sm' type='button' onClick={handleResetImage}>
                        Reset Image
                      </button>
                    </div>
                  </div>
                  <div className='image-margin'>
                    <div className='row pt-3'>
                      <label htmlFor='imageMargin' className='form-label'>
                        Image Margin
                      </label>
                      <div className='col-12'>
                        <input
                          id='imageMargin'
                          className='form-range'
                          type='range'
                          name='imageMargin'
                          min='0'
                          max='50'
                          step='5'
                          value={options.imageMargin}
                          onChange={handleOptions}
                        />
                        <div className='d-flex justify-content-between small'>
                          <span className='text-muted'>Low</span>
                          <span className='fw-bold'>{options.imageMargin}</span>
                          <span className='text-muted'>High</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-grid gap-2 d-md-flex justify-content-center pt-4'>
                    <button className='btn btn-outline-danger me-auto' type='button' onClick={handleResetOptions}>
                      Reset
                    </button>
                    <button className='btn btn-primary' type='button' onClick={handleSave}>
                      Save Style
                    </button>
                    <button className='btn btn-outline-success' type='button' onClick={handleSavedValues}>
                      Apply Saved Style
                    </button>
                  </div>
                </div>
              </div>
            </>
          } />
          <Route path="/email-verification" element={<EmailVerification />} /> {/* New page route */}
          <Route path="/forgot-password" element={<ForgotPasswordPage />} /> {/* New page route */}
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
