import { API_BASE_URL } from "../../Configs/AppConfig";
import fetch from "../../auth/fetchInterceptor";

const BASE_URL = API_BASE_URL;

export function requestForgotPassword(data) {
    return fetch.post(`${BASE_URL}/api/auth/forgotPassword`, data);
}

export function requestForgotPasswordPage(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/auth/generateRandomPassword`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/auth/generateRandomPassword?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}