import React, { useState, useContext } from 'react'
import { AppContext } from '../../Context'
import Submit from '../Submit'
import ReactGA from "react-ga4";

const TextForm = ({ onQrCodeGenerated }) => {
    const [values, setValues] = useState('')
    const { qrCode } = useContext(AppContext)

    const handleChange = (event) => {
        setValues(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        ReactGA.event({
            category: 'Form',
            action: 'Submitted Generate form',
            label: 'Text Generate form',
        });
        qrCode.update({
            data: values
        })
        onQrCodeGenerated(); // Notify parent that QR code is ready
    }

    return (
        <form className='qrForm-text' onSubmit={handleSubmit}>
            <div className='form-floating mb-3'>
                <textarea
                    id='text'
                    className='form-control'
                    name='text'
                    value={values}
                    cols={3}
                    onChange={handleChange}
                    placeholder='Add Text'
                    required
                />
                <label htmlFor='text'>Add Text</label>
            </div>
            <Submit />
        </form>
    )
}

export default TextForm