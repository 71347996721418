import React, { useContext, useState } from 'react';
import { AppContext } from '../../Context';
import Submit from '../Submit';
import { useAuth } from '../../AuthContext';
import { useLoginModal } from '../../LoginModalContext';
import { requestShortUrl } from '../../Services/shortUrl';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa'; // Import the FaExternalLinkAlt icon
import Notification from '../Notification'; // Import the Notification component
import ReactGA from "react-ga4";

function UrlForm({ onQrCodeGenerated }) {
    const [values, setValues] = useState('');
    const [shortUrl, setShortUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [notification, setNotification] = useState({ message: '', variant: '' });
    const { qrCode } = useContext(AppContext);
    const { token } = useAuth();
    const { openLoginModal } = useLoginModal();

    const handleChange = (event) => {
        setValues(event.target.value);
        setErrorMessage(''); // Clear error message on input change
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        ReactGA.event({
            category: 'Form',
            action: 'Submitted Generate form',
            label: 'Link Generate form',
        });
        qrCode.update({
            data: shortUrl || values,
        });
        onQrCodeGenerated(); // Notify parent that QR code is ready
    };

    const handleShortUrl = async (event) => {
        event.preventDefault();

        if (!token) {
            openLoginModal();
            return;
        }

        const longURL = values.trim();

        if (longURL === '') {
            setErrorMessage('Please enter a URL.');
            return;
        }

        try {
            const data = { url: longURL };
            const response = await requestShortUrl(data);
            const newShortUrl = response.data.redirectURL;
            setShortUrl(newShortUrl);
            qrCode.update({
                data: newShortUrl,
            });
        } catch (error) {
            setErrorMessage('Failed to shorten the URL. Please try again later.');
        }
        onQrCodeGenerated(); // Notify parent that QR code is ready
    };

    const handleCopy = () => {
        if (shortUrl) {
            navigator.clipboard.writeText(shortUrl).then(() => {
                setNotification({ message: 'URL copied to clipboard', variant: 'success' });
            }).catch((err) => {
                setNotification({ message: 'Failed to copy URL', variant: 'danger' });
            });
        }
    };

    const handleOpenLink = () => {
        if (shortUrl) {
            window.open(shortUrl, '_blank');
        }
    };

    const handleCloseNotification = () => {
        setNotification({ message: '', variant: '' });
    };

    return (
        <div>
            {notification.message && (
                <Notification
                    message={notification.message}
                    onClose={handleCloseNotification}
                    variant={notification.variant}
                />
            )}
            <form className='qrForm-url' onSubmit={handleSubmit}>
                <div className='form-floating mb-3'>
                    <input
                        id='url'
                        className='form-control'
                        type='url'
                        name='url'
                        value={values}
                        onChange={handleChange}
                        placeholder='Enter link (URL) here'
                        required
                    />
                    <label htmlFor='url'>Enter link (URL) here</label>
                    {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
                </div>
                <div>
                    <span style={{ marginRight: '10px' }}><Submit /></span>
                    <span>
                        <button className='btn btn-primary' type='button' onClick={handleShortUrl}>
                            Shorten URL and Generate QR Code
                        </button>
                    </span>
                </div>
                {shortUrl && (
                    <div className='my-2'>
                        <p>
                            LnkPro URL :- {shortUrl}
                            <FaCopy className='mx-3' style={{ cursor: 'pointer' }} onClick={handleCopy} />
                            <FaExternalLinkAlt style={{ cursor: 'pointer' }} onClick={handleOpenLink} />
                        </p>
                    </div>
                )}
            </form>
        </div>
    );
}

export default UrlForm;
