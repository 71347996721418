import React, { useState, useContext } from "react";
import { AppContext } from "../../Context";
import Submit from "../Submit";
import ReactGA from "react-ga4";

const initialValues = {
  email: "",
  subject: "",
  body: "",
};

const EmailForm = ({ onQrCodeGenerated }) => {
  const { qrCode } = useContext(AppContext);
  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Form',
      action: 'Submitted Generate form',
      label: 'Email Generate form',
    });
    const { email, subject, body } = values;

    const data = `mailto:${email}?subject=${subject}&body=${body}`;

    qrCode.update({
      data,
    });
    onQrCodeGenerated(); // Notify parent that QR code is ready
  };

  return (
    <form className="qrForm-email" onSubmit={handleSubmit}>
      <div className="form-floating mb-3">
        <input
          id="eEmail"
          className="form-control"
          pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
          type="text"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <label htmlFor="email">Email</label>
      </div>
      <div className="form-floating mb-3">
        <input
          id="eSubject"
          className="form-control"
          type="text"
          name="subject"
          value={values.subject}
          onChange={handleChange}
          placeholder="Subject"
        />
        <label htmlFor="subject">Subject</label>
      </div>
      <div className="form-floating mb-3">
        <textarea
          id="body"
          className="form-control"
          name="body"
          rows={3}
          value={values.body}
          onChange={handleChange}
          placeholder="Message"
        />
        <label htmlFor="body">Message</label>
      </div>

      <Submit />
    </form>
  );
};

export default EmailForm;
